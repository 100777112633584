// ** Redux Imports
import { combineReducers } from '@reduxjs/toolkit'

// ** CityApp Utilty
import { getCompanyLabel } from '@CityAppCompanySettings'

// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'

import storeKPI from '@src/views/StoreKPI/store'

import mainProductSaleProgress from '@src/views/MainProductSaleProgress/store'

import productLine_ForLine from '@src/views/ProductLine/ForLine/store'
import productLine_ForStyle from '@src/views/ProductLine/ForStyle/store'
import productLine_ForModel from '@src/views/ProductLine/ForModel/store'
import productLine_ForCategory from '@src/views/ProductLine/ForCategory/store'

import goodsSalesInventory from '@src/views/GoodsSalesInventory/store'

import sortAnalysis_ForGoodsId from '@src/views/SortAnalysis/ForGoodsId/store'
import sortAnalysis_ForSort01 from '@src/views/SortAnalysis/ForSort01/store'
import sortAnalysis_ForSort02 from '@src/views/SortAnalysis/ForSort02/store'
import sortAnalysis_ForSort03 from '@src/views/SortAnalysis/ForSort03/store'
import sortAnalysis_ForSort04 from '@src/views/SortAnalysis/ForSort04/store'
import sortAnalysis_ForSort05 from '@src/views/SortAnalysis/ForSort05/store'

import salesPeriod from '@src/views/SalesPeriod/store'
import counterAOV from '@src/views/CounterAOV/store'
import seasonDailySale from '@src/views/SeasonDailySale/store'

import salesCompare from '@src/views/SalesCompare/store'
import peersCompare from '@src/views/PeersCompare/store'
import storeInventory from '@src/views/StoreInventory/store'
import goodsInventory from '@src/views/GoodsInventory/store'
import memberPersonAnalysis from '@src/views/MemberPersonAnalysis/store'
import memberSaleAnalysis from '@src/views/MemberSaleAnalysis/store'
import purchaseStatistics from '@src/views/PurchaseStatistics/store'
import memberOccupancyAnalysis from '@src/views/MemberOccupancyAnalysis/store'
import memberBuyMutiAnalysis from '@src/views/MemberBuyMutiAnalysis/store'

import staffPunchIn from '@src/views/StaffPunchIn/store'
import brandSales_ForBrand from '@src/views/BrandSales/ForBrand/store'
import brandSales_ForBrandSort from '@src/views/BrandSales/ForBrandSort/store'
import empOnDuty from '@src/views/EmpOnDuty/store'
import purchaseNotDelivered from '@src/views/PurchaseNotDelivered/store'
import accountsReceivable from '@src/views/AccountsReceivable/store'
import empSaleReducer from '@src/views/EmpSale/store'

const standard = {
  staffPunchIn,
  empOnDuty,
  empSaleReducer,
  //業績比較
  storeKPI,
  salesCompare,
  peersCompare,
  brandSales_ForBrand,
  brandSales_ForBrandSort,
  peersCompare, 
 
  //會員分析
  memberPersonAnalysis,
  memberSaleAnalysis,
  memberOccupancyAnalysis,
  memberBuyMutiAnalysis,
  

  //銷售分析
  goodsSalesInventory,
  sortAnalysis_ForGoodsId,
  sortAnalysis_ForSort01, 
  sortAnalysis_ForSort02,
  sortAnalysis_ForSort03,
  sortAnalysis_ForSort04,
  sortAnalysis_ForSort05,
  salesPeriod, 
  counterAOV, 

  //庫存分析
  storeInventory, 
  goodsInventory,
  purchaseStatistics,
  purchaseNotDelivered,

  //帳務查詢
  accountsReceivable
}

const iris = {
  staffPunchIn,
  empOnDuty,
  //業績比較
  storeKPI,
  salesCompare,
  peersCompare,
  brandSales_ForBrand,
  brandSales_ForBrandSort,


  //會員分析
  memberPersonAnalysis,
  memberSaleAnalysis,
  memberOccupancyAnalysis,
  memberBuyMutiAnalysis,
  

  //銷售分析
  goodsSalesInventory,
  mainProductSaleProgress,
  productLine_ForLine,
  productLine_ForStyle,
  productLine_ForModel,
  productLine_ForCategory,
  salesPeriod, 
  counterAOV, 
  seasonDailySale,

  //庫存分析
  storeInventory, 
  goodsInventory,
  purchaseStatistics

  
}

const cityappReducer = () => {
  let result
  switch (getCompanyLabel()) {
    case 'cityapp': // IRIS
      result = iris
      break
    default:
      result = standard
  }
  return result
}

const appReducer = combineReducers({
  /* Vuexy */
  auth,
  navbar,
  layout,
  /* CityAPP */
  ...cityappReducer()
})

const rootReducer = (state, action) => {
  if (action.type === 'authentication/handleLogin' || action.type === 'authentication/handleLogout') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
