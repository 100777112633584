// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { showMessageBox, isMobileDevice } from '@CityAppHelper'
const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

const doLogout = (state) => {
  localStorage.removeItem(config.storageTokenKeyName || 'accessToken')
  localStorage.removeItem(config.storageRefreshTokenKeyName || 'refreshToken')
  console.log('doLogout s')
  document.location.href = "/login"
  console.log('doLogout e')
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('stockImg')
      localStorage.removeItem('stockImgExcel')
      localStorage.removeItem('menuCollapsed')
      localStorage.removeItem('i18nextLng')

      useJwt.logout()
        .then((response) => {
          doLogout()
        })
        .catch(error => {
          console.log(error)
          doLogout()
        })
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
